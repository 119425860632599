
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import { CheckoutStep } from '@/common/enums/CheckoutStep.enum';
import { clientModule } from '@/modules/client/client.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import { orderModule } from '@/modules/order/orderModule.vuex-module';

@Options({
	name: 'BookView',
	components: {},
})
export default class BookView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	public CheckoutStep = CheckoutStep;
	private allNavItems = [
		{ string: 'Add guests', type: CheckoutStep.RATE },
		{ string: 'Select date', type: CheckoutStep.DATE },
		{ string: 'Select time', type: CheckoutStep.TIME },
		{ string: 'Add-Ons', type: CheckoutStep.ADDON },
		{ string: 'Checkout', type: CheckoutStep.CHECKOUT },
	];
	get navItems() {
		let hideTime = orderModule.openActivity?.activity.AppointmentTypeKey == 2;
		return this.allNavItems.filter((item) => {
			switch (item.type) {
				case CheckoutStep.RATE:
				case CheckoutStep.DATE:
					return true;
				case CheckoutStep.TIME:
					return hideTime ? item.type != CheckoutStep.TIME : true;
				case CheckoutStep.ADDON:
					return orderModule.hasAddons;
				case CheckoutStep.CHECKOUT:
					return true;
			}
		});
	}
	get step(): CheckoutStep {
		const step = (this.$route.name as CheckoutStep) ?? CheckoutStep.RATE;
		return step;
	}
	mounted(): void {
		// probably should modify this to rebuild the orderModule openItem from url
		// but for now just go back if the page is refreshed
		if (!orderModule.openItem) {
			this.$router.push(`/portal/${this.clientLocationKey}`);
		} else {
			this.navTo(CheckoutStep.RATE);
		}
	}
	get itemActivities() {
		return orderModule.itemActivities;
	}
	get item(): Item | undefined {
		switch (this.type) {
			case PackageViewMode.INDIVIDUAL:
				var item = clientModule.location?.Individual.find((entity) => {
					return entity.EntityHierarchyKey == +this.typekey;
				});
				console.log(item);
				return item;
			case PackageViewMode.PACKAGE:
				return clientModule.location?.Packages.find((entity) => {
					return entity.PackageKey == this.typekey;
				});
		}
		console.log('Unable to find');
		return undefined;
	}
	canAccess(step: CheckoutStep): boolean {
		if (orderModule.isLoading) {
			return false;
		}
		let nogc = !(orderModule._activeOrder?.hasPaidPayment ?? false); // && !(orderModule._activeOrder?.hasPromoCodes ?? false);
		switch (step) {
			case CheckoutStep.RATE:
				return nogc;
			case CheckoutStep.DATE:
				return orderModule.allRatesSelected && nogc;
			case CheckoutStep.TIME:
				return orderModule.allDatesSelected && nogc;
			case CheckoutStep.ADDON:
				return orderModule.allTimesSelected && nogc;
			case CheckoutStep.CHECKOUT:
				return orderModule.allAddonsSelected;
		}
	}
	navTo(step: CheckoutStep): void {
		if (this.canAccess(step)) {
			this.$router.push(`/portal/${this.clientLocationKey}/${this.type}/${this.typekey}/book/${step}`);
		}
	}
	cancel() {
		orderModule.abandonOrderChanges();
		this.$router.push(`/portal/${this.clientLocationKey}`);
	}
}
